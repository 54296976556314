import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isObject } from 'lodash';

import withStyles from '@mui/styles/withStyles';

import { InputAdornment, Button, IconButton, Grid } from '@mui/material';

import Visibility from '../../components/icons/Visibility';
import VisibilityOff from '../../components/icons/VisibilityOff';

import Card from '../../components/Extended/Card/Card';
import CardHeader from '../../components/Extended/Card/CardHeader';
import CardBody from '../../components/Extended/Card/CardBody';
import CardFooter from '../../components/Extended/Card/CardFooter';
import CustomInput from '../../components/Extended/CustomInput';
import Keys from '../../components/icons/Keys';
import Envelope from '../../components/icons/Envelope';

import authPageStyle from '../../assets/jss/pages/authPageStyle';

import { Router, Link } from '../../server/routes';
import api from '../../core/api';
import actions from '../../core/store/actions/auth';
import { VALIDATION_ERRORS, DASHBOARDS_MAP } from '../../core/constants';
import { EMAIL_REGEXP } from '../../core/regexp';
import { withTranslation } from '../../i18n';

class Login extends Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimation: 'cardHidden',
      email: '',
      password: '',
      errors: {
        email: null,
        password: null,
      },
      showPwd: false,
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(() => {
      this.setState({ cardAnimation: '' });
    }, 700);
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  validateField = (name, value) =>
    name === 'email' ? this.validateEmail(value) : this.validatePassword(value);

  validateEmail = value => {
    const { t } = this.props;
    if (!value.length) return t(VALIDATION_ERRORS.FIELD_IS_REQUIRED);
    if (!EMAIL_REGEXP.test(value)) return t(VALIDATION_ERRORS.INVALID_EMAIL);
    return null;
  };

  validatePassword = value => {
    const { t } = this.props;
    if (!value.length) return t(VALIDATION_ERRORS.FIELD_IS_REQUIRED);
    return null;
  };

  handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.handleLogin();
    }
  };

  handleInputChange = name => ev => {
    const {
      target: { value },
    } = ev;

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: null,
      },
    });
  };

  handleInputBlur = name => () => {
    const value = this.state[name];
    const error = this.validateField(name, value);
    if (error) {
      this.setState({
        errors: {
          [name]: error,
        },
      });
    }
  };

  handleLogin = async () => {
    const { email, password, errors } = this.state;
    const { dispatch, t } = this.props;
    if (!email.length || !password.length) return;
    const emailError = this.validateEmail(email);
    const pwdError = this.validatePassword(password);
    if (emailError || pwdError) {
      this.setState({
        errors: {
          ...(emailError && { email: emailError }),
          ...(pwdError && { password: pwdError }),
        },
      });
      return;
    }

    try {
      const { user, error } = await api.Auth.login({
        email,
        password,
      });
      if (user) {
        const {
          role: { code },
        } = user;
        dispatch(actions.setCurrentUser(user));
        Router.pushRoute(DASHBOARDS_MAP[code]);
      } else {
        // TODO: use error responses from server
        this.setState({
          errors: {
            email: t(VALIDATION_ERRORS.UNAUTHORIZED),
            password: t(VALIDATION_ERRORS.UNAUTHORIZED),
          },
        });
      }
    } catch (err) {
      const errors = isObject(err?.error)
        ? {
            email: t(err.error?.email || ''),
            password: t(err.error?.password || ''),
          }
        : {
            email: t(VALIDATION_ERRORS.UNAUTHORIZED),
            password: t(VALIDATION_ERRORS.UNAUTHORIZED),
          };

      this.setState({
        errors,
      });
    }
  };

  handleAdornmentToggle = () => {
    this.setState({
      showPwd: !this.state.showPwd,
    });
  };

  render() {
    const { email, password, cardAnimation, errors, showPwd } = this.state;

    const { classes, t } = this.props;
    return (
      <div className={classes.container}>
        <Grid container justifyContent="center">
          <Grid item flexDirection="column">
            <form>
              <Card login className={`${classes.card} ${classes[cardAnimation]}`}>
                <CardHeader
                  style={{ marginTop: -20 }}
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="info"
                >
                  <h1 className={classes.cardTitle}>{t('pages.auth.card_label_login')}</h1>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    error={!!errors.email}
                    labelText={errors ? errors.email : ''}
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: t('pages.auth.email_input.placeholder'),
                      onChange: this.handleInputChange('email'),
                      onBlur: this.handleInputBlur('email'),
                      value: email,
                      startAdornment: (
                        <InputAdornment position="start" style={{ fontSize: 20 }}>
                          <Envelope width={20} height={15} viewBox="0 0 20 15" fontSize="inherit" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    error={!!errors.password}
                    labelText={errors ? errors.password : ''}
                    id="password"
                    type={showPwd ? 'text' : 'password'}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: t('pages.auth.pwd_input.placeholder'),
                      onChange: this.handleInputChange('password'),
                      onBlur: this.handleInputBlur('password'),
                      onKeyPress: this.handleKeyPress,
                      value: password,
                      startAdornment: (
                        <InputAdornment position="start" style={{ fontSize: 25 }}>
                          <Keys width={18} height={25} viewBox="0 0 18 25" fontSize="inherit" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle visibility"
                            onClick={this.handleAdornmentToggle}
                            size="large"
                          >
                            {showPwd ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Link route="/auth/recovery">
                    <Button className={classes.forgotPasswordBtn} color="primary">
                      {t('pages.auth.forgot_pwd_label')}
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </form>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleLogin}
              className={`${classes.loginBtn} ${classes[cardAnimation]}`}
            >
              {t('pages.auth.login_btn_label')}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('common'),
  connect(({ auth }, ownProps) => ({
    ...ownProps,
    auth,
  })),
  withStyles(authPageStyle),
)(Login);
