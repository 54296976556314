import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import cardFooterStyle from '../../../assets/jss/components/cardFooterStyle';

function CardFooter({ ...props }) {
  const { classes, className, children, plain, stats, ...rest } = props;
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [classes.cardFooterStats]: stats,
    [className]: className !== undefined,
  });
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
}

CardFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  stats: PropTypes.bool,
};

export default withStyles(cardFooterStyle)(CardFooter);
